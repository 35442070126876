import { template as template_ca5eb1568f8a4100988bd55cbb189a5a } from "@ember/template-compiler";
const FKLabel = template_ca5eb1568f8a4100988bd55cbb189a5a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
